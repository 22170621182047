import { BaseModel, enumField, ModelInstance, ToBackHook, ToFrontHook } from './util';


export enum LeaguePlayerFieldTypes {
  text = 1,
  select,
  file,
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    league_id: 'leagueId',
    name: 'name',
    type: 'type',
    is_multiple: 'isMultiple',
    show_in_application_file: 'showInApplicationFile',
    variants: 'variants',
    sort: 'sort',
  },
  relation: {
    type: enumField(LeaguePlayerFieldTypes)
  }
})
export class LeaguePlayerField extends BaseModel {
  private _variantsMapping = {};
  private _variants = [];

  id: number;
  leagueId: number;
  name: string;
  type: LeaguePlayerFieldTypes;
  isMultiple: boolean;
  showInApplicationFile: boolean;
  sort: number;

  set variants(value: { uuid: string, name: string }[]) {
    this._variants = value;
    this._variantsMapping = value.reduce((acc, item) => ({
      ...acc,
      [item.uuid]: item.name
    }), {});
  }

  get variants(): {uuid: string, name: string}[] {
    return this._variants;
  }

  getName(uuid: string): string {
    return this._variantsMapping[uuid];
  }

  @ToFrontHook
  static toFront(data: any): any {
  }

  @ToBackHook
  static toBack(data: any): any {
  }
}
