import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField } from '@core/models/util';
import { TournamentTeam } from '@core/models/tournament-team';
import { TeamUser } from '@core/models/team-user';
import { TournamentDisqualification } from '@core/models/tournament-disqualification';
import { LeaguePlayer } from '@core/models/league-player';
import * as moment from 'moment';

export enum PlayoffAdmissionEnum {
  auto = 1,
  admitted,
  not_admitted,
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    active: 'active',
    tournament_team: 'tournamentTeam',
    tournament_team_id: 'tournamentTeamId',
    team_user: 'teamUser',
    disqualified: 'disqualified',
    disqualification: 'disqualification',
    conditional_disqualification: 'conditionalDisqualification',
    league_player: 'leaguePlayer',
    games_count: 'gamesCount',
    mvp_count: 'mvpCount',
    disqualifications_count: 'disqualificationsCount',
    playoff_admission: 'playoffAdmission',
    created_at: 'createdAt',
  },
  relation: {
    tournamentTeam: TournamentTeam,
    teamUser: TeamUser,
    disqualification: TournamentDisqualification,
    conditionalDisqualification: TournamentDisqualification,
    leaguePlayer: LeaguePlayer,
    playoffAdmission: enumField(PlayoffAdmissionEnum),
  }
})
export class TournamentTeamUser extends BaseModel {
  id: number;
  active: boolean;
  tournamentTeam: TournamentTeam;
  tournamentTeamId: number;
  teamUser: TeamUser;
  disqualified: boolean;
  disqualification: TournamentDisqualification;
  conditionalDisqualification: TournamentDisqualification;
  leaguePlayer: LeaguePlayer;
  gamesCount: number;
  mvpCount: number;
  disqualificationsCount: number;
  playoffAdmission: PlayoffAdmissionEnum;
  createdAt: moment.Moment;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

