import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

export interface TextConfig {
  positionX?: 'left' | 'center' | 'right' | number;
  positionY?: 'top' | 'center' | 'bottom' | number;
  width?: number;
  height?: number;
  font?: string;
  color?: string;
  strokeColor?: string;
}

const defaultTextConfig: TextConfig = {
  positionX: 'center',
  positionY: 'center',
};


@Directive({
  selector: 'canvas[mtgArcMaskText]'
})
export class ArcTextDirective implements OnChanges {
  @Input()
  text: string;
  @Input()
  textConfig: TextConfig = defaultTextConfig;

  constructor(
    private elementRef: ElementRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text && this.text) {
      this.drawText();
    }
  }

  private drawText() {
    const f = new FontFace('Montserrat-ExtraBold', 'url(/assets/fonts/montserrat/Montserrat-ExtraBold.otf)');
    const canvas = this.elementRef.nativeElement;

    const fontSetting = this.textConfig.font ? this.textConfig.font : '100px/100px Montserrat-ExtraBold';
    const strokeStyle = this.textConfig.strokeColor ? this.textConfig.strokeColor : '#ef7f1a';
    const text = this.text.toUpperCase();
    const color = this.textConfig.color ? this.textConfig.color : 'transparent';
    let angle = Math.PI * 0.6;
    let radius = 350;
    if (text.length < 5) {
      angle = Math.PI * 0.3;
      radius = 350;
    } else if (text.length < 8) {
      angle = Math.PI * 0.5;
      radius = 350;
    }
    f.load().then(function(font) {
      document.fonts.add(font);
      const ctx = canvas.getContext('2d');
      const maskSizeRatio = canvas.width / canvas.height;
      const maskHeight = canvas.height;
      const maskWidth = canvas.width;
      let maskTop = 0;
      let maskLeft = 0;
      maskTop = maskHeight / 2;
      maskLeft = maskWidth / 2;



      ctx.font = fontSetting;
      ctx.strokeStyle = strokeStyle;
      ctx.fillStyle = color;
      ctx.textAlign = 'center';
      ctx.translate(380, 420);
      ctx.rotate(-1 * angle / 2);

      for (let i = 0; i < text.length; i++) {
        ctx.rotate(angle / text.length);
        ctx.save();
        ctx.translate(0, -1 * radius);
        ctx.fillText(text[i], 0, 0);
        ctx.restore();
      }

    });
  }
}
