<div class="playoff-wrapper" *ngIf="playoff">
  <div class="playoff-grid">
    <div class="tour" *ngFor="let _ of generateArray(toursCount); let i = index;">
      <ng-container *ngIf="i < toursCount - 1">
        <div [ngClass]="{'active': currentRound === i + 1 }" class="round-number">{{ getRoundNumber(i) }}</div>
        <ng-container *ngIf="!isWinnerFinal(i)">
          <div
            *ngFor="let _ of generateArray(getMatchesCount(i) / 2); let j = index;"
            class="pair"
            [ngClass]="{prevSemi: isPrevSemi(i), semi: isSemi(i)}"
          >
            <mtg-tournament-playoff-game
              *ngFor="let _ of generateArray(2); let k = index;"
              [games]="getGames(i, j * 2 + k)"
              [gameNumber]="getGameNumber(i, j * 2 + k)"
              [gameIndex]="j * 2 + k"
              [gameRound]="i"
              [winnerTeam]="getNextGameTeam(i, j * 2 + k)"
              [winnerCompetitorTeam]="getNextGameTeam(i, j * 2 + k, 1)"
              [teamsCount]="playoff.settings.teamsCount"
              [gameRoute]="gameRoute"
              [canManage]="canManage"
              [openInNewWindow]="openGameInNewWindow"
              [isDoubleElimination]="true"
              (gameForm)="gameForm.emit($event)"
              [ngClass]="{prevSemi: isPrevSemi(i), semi: isSemi(i), smallRound: i === toursCount - 3 && toursCount === 3}"
            ></mtg-tournament-playoff-game>
          </div>
        </ng-container>
        <ng-container *ngIf="isWinnerFinal(i)">
          <div class="pair winner-final">
            <mtg-tournament-playoff-game
              [games]="getGames(i, 0)"
              [gameNumber]="getGameNumber(i, 0)"
              [gameIndex]="0"
              [gameRound]="i"
              [winnerTeam]="getNextGameTeam(i, 0)"
              [winnerCompetitorTeam]="getNextGameTeam(i, 0, 1)"
              [teamsCount]="playoff.settings.teamsCount"
              [gameRoute]="gameRoute"
              [canManage]="canManage"
              [openInNewWindow]="openGameInNewWindow"
              [isDoubleElimination]="true"
              (gameForm)="gameForm.emit($event)"
              [ngClass]="{'winner-final': true}"
            ></mtg-tournament-playoff-game>
          </div>
        </ng-container>
      </ng-container>
      <div class="final-pair-tour-number" *ngIf="i === toursCount - 1">
        <div [ngClass]="{'active': currentRound >= toursCount}" class="round-number">{{ getRoundNumber(i, 0) }}</div>
        <div class="round-number">{{ getRoundNumber(i, 1) }}</div>
      </div>
      <div class="final-pair" *ngIf="i === toursCount - 1">
        <mtg-tournament-playoff-game
          [games]="getGames(i, 0)"
          [gameNumber]="getGameNumber(i, 0)"
          [gameIndex]="0"
          [gameRound]="i"
          [winnerTeam]="getNextGameTeam(i, 0)"
          [winnerCompetitorTeam]="getNextGameTeam(i, 0, 1)"
          [teamsCount]="playoff.settings.teamsCount"
          [gameRoute]="gameRoute"
          [canManage]="canManage"
          [openInNewWindow]="openGameInNewWindow"
          [isDoubleElimination]="true"
          (gameForm)="gameForm.emit($event)"
          class="final"
        ></mtg-tournament-playoff-game>
        <mtg-tournament-playoff-game
          [games]="getGames(i, 1)"
          [gameNumber]="getGameNumber(i, 1)"
          [gameIndex]="1"
          [gameRound]="i"
          [winnerTeam]="getNextGameTeam(i, 1)"
          [winnerCompetitorTeam]="getNextGameTeam(i, 1, 1)"
          [teamsCount]="playoff.settings.teamsCount"
          [gameRoute]="gameRoute"
          [canManage]="canManage"
          [openInNewWindow]="openGameInNewWindow"
          [isDoubleElimination]="true"
          (gameForm)="gameForm.emit($event)"
          class="third-place"
          [ngClass]="{withLoserFinal: playoff.settings.loserFinalRounds > 0}"
        ></mtg-tournament-playoff-game>
      </div>
    </div>
  </div>
</div>
