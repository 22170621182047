import { enumField, ModelInstance, ToFrontHook, ToBackHook, BaseModel, DateTimeField } from './util';
import { GameUser } from './game-user';
import { GameLogBase } from './game-log-base';


export enum FootballGameLogTypes {
  enter_game = 1,
  exit_game,
  shot_miss,
  shot_on_goal,
  shot_blocked,
  goal,
  penalty_attempt,
  penalty_goal,
  penalty_save,
  small_penalty_attempt,
  small_penalty_goal,
  small_penalty_save,
  assist,
  block_shot,
  corner,
  free_kick,
  foul,
  save,
  yellow_card,
  red_card,
  perfect_pass,
  loss,
  steal,
  out,
  timeout,
  auto_goal,
  end_game_penalty_miss,
  end_game_penalty_goal,
  end_game_penalty_save,
  duel_won,
  duel_lost,
  double_goal,
  super_game,
  color_ball,
  bonus,
}


@ModelInstance({
  mappingFields: {
    id: 'id',
    unique_id: 'uniqueId',
    game_id: 'gameId',
    game_user_id: 'gameUserId',
    team_id: 'teamId',
    log_type: 'logType',
    datetime: 'datetime',
    time: 'time',
    period: 'period',
    active: 'active',
    is_highlight: 'isHighlight',
    is_goalie: 'isGoalie',
  },
  relation: {
    datetime: DateTimeField,
    logType: enumField(FootballGameLogTypes)
  }
})
export class FootballGameLog extends BaseModel implements GameLogBase {
  id: number;
  uniqueId: string;
  gameId: number;
  gameUserId: number;
  gameUser: GameUser;
  teamId: number;
  logType: FootballGameLogTypes;
  datetime: Date;
  time: number;
  period: number;
  active = true;
  teamScore: number;
  competitorTeamScore: number;
  isHighlight: boolean;
  isGoalie: boolean;

  compare(model: FootballGameLog): number {
    if (this.time === model.time && this.period === model.period) {
      return this.datetime.getTime() < model.datetime.getTime() ? 1 : -1;
    }
    if (this.period === model.period) {
      return this.time < model.time ? 1 : -1;
    }
    return this.period < model.period ? 1 : -1;
  }

  get timeFormatted(): string {
    const minutes = Math.floor(this.time / 60);
    const seconds = this.time - minutes * 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  isScoreType(): boolean {
    return [
      FootballGameLogTypes.goal,
      FootballGameLogTypes.penalty_goal,
      FootballGameLogTypes.small_penalty_goal,
      FootballGameLogTypes.auto_goal,
      FootballGameLogTypes.end_game_penalty_goal,
      FootballGameLogTypes.double_goal
    ].indexOf(this.logType) > -1;
  }

  isAfter(log: FootballGameLog): boolean {
    if (this.time === log.time && this.period === log.period) {
      return this.id > log.id;
    }
    if (this.period === log.period) {
      return this.time > log.time;
    }
    return this.period > log.period;
  }

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
