import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { LeaguePlayer } from '@core/models/league-player';
import { LeaguePlayerForm } from '@core/models/league-player-form';
import { TournamentTeamUser } from '@core/models/tournament-team-user';

export interface LeaguePlayersListFilters {
  query?: string;
  hasChanges?: boolean;
  isLeagueUser?: number;
  expand?: boolean;
}

@Injectable()
export class OrgLeaguePlayerService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  getPlayerList(leagueId: number, page: number, size: number, filters?: LeaguePlayersListFilters): Observable<PaginatedResponse<LeaguePlayer[]>> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (filters.query) {
      params = params.set('query', filters.query);
    }
    if (filters.hasChanges) {
      params = params.set('has_changes', '1');
    }
    if (filters.expand) {
      params = params.set('expand', '1');
    }
    if (filters.isLeagueUser) {
      params = params.set('created_by_league', filters.isLeagueUser.toString());
    }
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/players/`, {observe: 'response', params})
      .pipe(map(response => ({
        total: +response.headers.get('X-Page-Count'),
        data: LeaguePlayer.toFront(response.body)
      })));
  }

  getPlayerById(playerId: number): Observable<LeaguePlayer> {
    return this.httpClient.get(`/org/api/v1/league_player/${playerId}/`)
      .pipe(map(result => LeaguePlayer.toFront(result)));
  }

  updatePlayer(playerId: number, data: any): Observable<LeaguePlayer> {
    return this.httpClient.patch(`/org/api/v1/league_player/${playerId}/`, LeaguePlayerForm.toBack(data))
      .pipe(map(result => LeaguePlayer.toFront(result)));
  }

  deletePlayer(playerId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/league_player/${playerId}/`);
  }

  confirmChanges(playerId: number): Observable<LeaguePlayer> {
    return this.httpClient.post(`/org/api/v1/league_player/${playerId}/confirm_changes/`, {})
      .pipe(map(result => LeaguePlayer.toFront(result)));
  }

  declineChanges(playerId: number): Observable<LeaguePlayer> {
    return this.httpClient.post(`/org/api/v1/league_player/${playerId}/decline_changes/`, {})
      .pipe(map(result => LeaguePlayer.toFront(result)));
  }

  getTournamentUsers(playerId: number): Observable<TournamentTeamUser[]> {
    return this.httpClient.get(`/org/api/v1/league_player/${playerId}/tournament_users/`).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }
}
