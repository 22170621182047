<div class="playoff-wrapper" *ngIf="playoff">
  <div class="playoff-grid">
    <div class="tour" *ngFor="let _ of generateArray(toursCount); let i = index;">
      <ng-container *ngIf="getMatchesCount(i) > 1">
        <div [ngClass]="{'active': currentRound === i + 1 || (i + 1 === toursCount && currentRound >= toursCount)}" class="round-number">{{ getRoundNumber(i) }}</div>
        <div class="pair" [ngClass]="{firstRound: i % 2 === 0, semi: i === toursCount - 1}" *ngFor="let _ of generateArray(getMatchesCount(i) / 2); let j = index;">
          <mtg-tournament-playoff-game
            *ngFor="let _ of generateArray(2); let k = index;"
            [games]="getGames(i, j * 2 + k)"
            [gameNumber]="getGameNumber(i, j * 2 + k)"
            [gameIndex]="j * 2 + k"
            [gameRound]="i"
            [winnerTeam]="getNextGameTeam(i, j * 2 + k)"
            [winnerCompetitorTeam]="getNextGameTeam(i, j * 2 + k, 1)"
            [teamsCount]="playoff.settings.teamsCount"
            [gameRoute]="gameRoute"
            [canManage]="canManage"
            [openInNewWindow]="openGameInNewWindow"
            [isLoserBracketGame]="true"
            (gameForm)="gameForm.emit($event)"
            [ngClass]="{firstRound: i % 2 === 0, semi: i === toursCount - 1}"
          ></mtg-tournament-playoff-game>
        </div>
      </ng-container>
      <ng-container *ngIf="getMatchesCount(i) === 1">
        <div [ngClass]="{'active': currentRound === i + 1 || (i + 1 === toursCount && currentRound >= toursCount)}" class="round-number">{{ getRoundNumber(i) }}</div>
        <div class="pair" [ngClass]="{firstRound: i !== toursCount - 1, semi: i === toursCount - 1}">
          <mtg-tournament-playoff-game
            [games]="getGames(i, 0)"
            [gameNumber]="getGameNumber(i, 0)"
            [gameIndex]="0"
            [gameRound]="i"
            [winnerTeam]="getNextGameTeam(i, 0)"
            [winnerCompetitorTeam]="getNextGameTeam(i, 0, 1)"
            [teamsCount]="playoff.settings.teamsCount"
            [gameRoute]="gameRoute"
            [canManage]="canManage"
            [openInNewWindow]="openGameInNewWindow"
            [isLoserBracketGame]="true"
            (gameForm)="gameForm.emit($event)"
            [ngClass]="{firstRound: i !== toursCount - 1, semi: i === toursCount - 1}"
          ></mtg-tournament-playoff-game>
        </div>
      </ng-container>
    </div>
  </div>
</div>
