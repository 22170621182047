import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Tournament } from '@core/models/tournament';
import { map } from 'rxjs/operators';
import { TournamentNews } from '@core/models/tournament-news';
import { Game, GameStatuses } from '@core/models/game';
import { TournamentGroup } from '@core/models/tournament-group';
import { TournamentTeam } from '@core/models/tournament-team';
import { TournamentUserStatistic } from '@core/models/tournament-user-statistic';
import { TournamentTeamStatistic } from '@core/models/tournament-team-statistic';
import { TournamentInvite } from '@core/models/tournament-invite';
import { VolleyballStatistic } from '@core/models/volleyball-statistic';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import {Playoff} from '@core/models/playoff';
import { TournamentStage } from '@core/models/tournament-stage';
import { TournamentStageTeam } from '@core/models/tournament-stage-team';
import { TournamentTeamUserInvite } from '@core/models/tournament-team-user-invite';
import { TournamentJoinTeam } from '@core/models/tournament-join-team';
import { TournamentTeamUser } from '@core/models/tournament-team-user';
import { BasketballStatistic } from '@core/models/basketball-statistic';
import { LeagueUserPermissions } from '@core/models/league-user';
import { HockeyStatistic } from '@core/models/hockey-statistic';
import { WaterpoloStatistic } from '@core/models/waterpolo-statistic';
import { applyGamesFilters } from '@core/services/tournament-admin.service';
import { FootballStatistic } from '@core/models/football-statistic';
import { HandballStatistic } from '@core/models/handball-statistic';
import { RugbyStatistic } from '@core/models/rugby-statistic';
import { WrestballStatistic } from '@core/models/wrestball-statistic';
import { WrestballGameStatistic } from '@core/models/wrestball-game-statistic';
import { GameStage, GameTimelineStages, getGameStages } from '@core/models/game-timeline-stages';

export interface TournamentStatisticFilters {
  tournament_stage_id?: number;
  tournament_tour?: number;
  playoff_stage?: number;
  date_from?: string;
  date_to?: string;
  per_game?: boolean;
  team_id?: number;
}

export type StatisticGroupByTypes = 'team' | 'user' | 'team_user' | 'tournament_team' | 'tournament_team_user' | 'month' | 'win_loses';

export interface StatisticFilters {
  tournament_id?: number;
  tournament_team_id?: number;
  tournament_team_user_id?: number;
  team_id?: number;
  team_user_id?: number;
  user_id?: number;
  tournament_stage_id?: number;
  tournament_tour?: number;
  tournament_group_id?: number;
  tournament_round_id?: number;
  game_id?: number;
  division_id?: number;
  playoff_stage?: number;
  playoff_id?: number;
  is_playoff?: boolean;
  date_from?: string;
  date_to?: string;
  sport_id?: number;
  per_game?: boolean;
  group_by: StatisticGroupByTypes;
}

export interface TournamentGamesFilters {
  tournamentStageId?: number;
  tournamentTour?: number;
  teamId?: number;
  playoffStage?: number;
  playoffId?: number;
  playoffRound?: number;
  tournamentRoundId?: number;
  divisionId?: number;
  groupId?: number;
  status?: GameStatuses;
}

export interface GameMediaFilters {
  tournamentTour?: number;
  tournamentStage?: number;
  team?: number;
  groupId?: number;
}

@Injectable()
export class TournamentService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getTournament(alias: string|number): Observable<Tournament> {
    return this.httpClient.get(`/api/v1/tournament/${alias}/`).pipe(
      map(result => Tournament.toFront(result))
    );
  }

  getTournamentStages(tournamentId: number): Observable<TournamentStage[]> {
    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/stages/`).pipe(
      map(data => TournamentStage.toFront(data))
    );
  }

  getNewsList(tournamentId: number): Observable<TournamentNews[]> {
    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/news/`).pipe(
      map(result => TournamentNews.toFront(result))
    );
  }

  getNewsItem(id: number): Observable<TournamentNews> {
    return this.httpClient.get(`/api/v1/tournament_news/${id}/`).pipe(
      map(result => TournamentNews.toFront(result))
    );
  }

  getGamesStages(tournamentId: number): Observable<any> {
    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/games_stages/`);
  }

  getGamesTimelineStages(tournamentId: number): Observable<GameStage[]> {
    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/games_stages/`).pipe(
      map(response => getGameStages(GameTimelineStages.toFront(response))),
    );
  }

  getGames(tournamentId: number, page: number = 1, size?: number, filters: TournamentGamesFilters = {}, tournamentStageId?: number): Observable<PaginatedResponse<Game[]>> {
    let params = new HttpParams().set('page', page.toString());
    if (size) {
      params = params.set('size', size.toString());
    }
    params = applyGamesFilters(filters, params);

    let url = `/api/v1/tournament/${tournamentId}/games/`;
    if (tournamentStageId) {
      url = `/api/v1/tournament_stage/${tournamentStageId}/games/`;
    }

    return this.httpClient
      .get(url, {params, observe: 'response'})
      .pipe(
        map((data: HttpResponse<any>) => {
          return {
            total: +data.headers.get('X-Page-Count'),
            data: Game.toFront(data.body)
          };
        })
      );
  }

  getGroups(tournamentId: number): Observable<TournamentGroup[]> {
    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/groups/`).pipe(
      map(result => TournamentGroup.toFront(result))
    );
  }

  getTeams(tournamentId: number, short?: boolean, groupId?: number): Observable<TournamentTeam[]> {
    let params = new HttpParams();
    if (short) {
      params = params.set('short', '1');
    }
    if (groupId) {
      params = params.set('group_id', groupId.toString());
    }
    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/teams/`, {params}).pipe(
      map(result => TournamentTeam.toFront(result))
    );
  }

  getTeam(tournamentTeamId: number): Observable<TournamentTeam> {
    return this.httpClient.get(`/api/v1/tournament_team/${tournamentTeamId}/`).pipe(
      map(result => TournamentTeam.toFront(result))
    );
  }

  getStageTeams(tournamentId: number): Observable<TournamentStageTeam[]> {
    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/stage_teams/`).pipe(
      map(result => TournamentStageTeam.toFront(result))
    );
  }

  getUsersStatistic(tournamentId: number, filters?: TournamentStatisticFilters): Observable<TournamentUserStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }
      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/user_statistic/`, {params}).pipe(
      map(result => TournamentUserStatistic.toFront(result))
    );
  }

  getVolleyballUsersStatistic(tournamentId: number, filters?: TournamentStatisticFilters): Observable<VolleyballStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }
      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/volleyball_user_statistic/`, {params}).pipe(
        map(result => VolleyballStatistic.toFront(result))
    );
  }

  getTeamStatistic(tournamentId: number, filters?: TournamentStatisticFilters): Observable<TournamentTeamStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }

      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/team_statistic/`, {params}).pipe(
      map(result => TournamentTeamStatistic.toFront(result))
    );
  }

  getBasketballStatistic(filters?: StatisticFilters): Observable<BasketballStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }

      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/basketball_statistic/`, {params}).pipe(
      map(result => BasketballStatistic.toFront(result))
    );
  }

  getVolleyballStatistic(filters?: StatisticFilters): Observable<VolleyballStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }

      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/volleyball_statistic/`, {params}).pipe(
      map(result => VolleyballStatistic.toFront(result))
    );
  }

  getHockeyStatistic(filters?: StatisticFilters): Observable<HockeyStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }

      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/hockey_statistic/`, {params}).pipe(
      map(result => HockeyStatistic.toFront(result))
    );
  }

  getFootballStatistic(filters?: StatisticFilters): Observable<FootballStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }

      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/football_statistic/`, {params}).pipe(
      map(result => FootballStatistic.toFront(result))
    );
  }

  getHandballStatistic(filters?: StatisticFilters): Observable<HandballStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }

      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/handball_statistic/`, {params}).pipe(
      map(result => HandballStatistic.toFront(result))
    );
  }

  getRugbyStatistic(filters?: StatisticFilters): Observable<RugbyStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }

      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/rugby_statistic/`, {params}).pipe(
      map(result => RugbyStatistic.toFront(result))
    );
  }

  getWaterpoloStatistic(filters?: StatisticFilters): Observable<WaterpoloStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }

      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/waterpolo_statistic/`, {params}).pipe(
      map(result => WaterpoloStatistic.toFront(result))
    );
  }

  getWrestballStatistic(filters?: StatisticFilters): Observable<WrestballStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }

      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/wrestball_statistic/`, {params}).pipe(
      map(result => WrestballGameStatistic.toFront(result))
    );
  }

  getVolleyballTeamStatistic(tournamentId: number, filters?: TournamentStatisticFilters): Observable<VolleyballStatistic[]> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }

      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/volleyball_team_statistic/`, {params}).pipe(
        map(result => VolleyballStatistic.toFront(result))
    );
  }

  getPlayoff(tournamentId: number): Observable<Playoff[]> {
    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/playoff/`).pipe(
      map(result => Playoff.toFront(result))
    );
  }

  getPlayoffGames(playoffId: number): Observable<Game[]> {
    return this.httpClient.get(`/api/v1/tournament_playoff/${playoffId}/games/`).pipe(
      map(response => Game.toFront(response))
    );
  }

  joinTournament(tournamentId: number, teamId: number, invites: TournamentTeamUserInvite[]): Observable<TournamentInvite> {
    const body = {
      team: {id: teamId},
      user_invites: TournamentTeamUserInvite.toBack(invites)
    };
    return this.httpClient.post(`/api/v1/tournament/${tournamentId}/join/`, body)
      .pipe(map(data => TournamentInvite.toFront(data)));
  }

  myPermission(tournamentId: number): Observable<LeagueUserPermissions[]> {
    return this.httpClient.get<any>(`/org/api/v1/tournament/${tournamentId}/permission/`).pipe(
      map(data => (data.permissions || []).map(item => LeagueUserPermissions[item]))
    );
  }

  getTeamUsers(tournamentTeamId: number): Observable<TournamentTeamUser[]> {
    return this.httpClient.get(`/api/v1/tournament_team/${tournamentTeamId}/users/`).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  getTournamentUser(tournamentUserId: number): Observable<TournamentTeamUser> {
    return this.httpClient.get(`/api/v1/tournament_team_user/${tournamentUserId}`).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  getTournamentUserGames(tournamentUserId: number): Observable<Game[]> {
    return this.httpClient.get(`/api/v1/tournament_team_user/${tournamentUserId}/games/`).pipe(
      map(data => Game.toFront(data))
    );
  }

  getTeamUserInvites(tournamentTeamId: number): Observable<TournamentTeamUserInvite[]> {
    return this.httpClient.get(`/api/v1/tournament_team/${tournamentTeamId}/user_invites/`).pipe(
      map(data => TournamentTeamUserInvite.toFront(data))
    );
  }

  sendTeamUserInvites(tournamentTeamId: number, userInvites: TournamentTeamUserInvite[]): Observable<TournamentTeamUserInvite[]> {
    return this.httpClient.post(
      `/api/v1/tournament_team/${tournamentTeamId}/user_invites/bulk/`,
      TournamentTeamUserInvite.toBack(userInvites)
    ).pipe(
      map(data => TournamentTeamUserInvite.toFront(data))
    );
  }

  deleteTeamUserInvite(inviteId: number): Observable<any> {
    return this.httpClient.delete(`/api/v1/tournament_user_invite/${inviteId}/`);
  }

  addUsers(tournamentTeamId: number, users: TournamentTeamUser[]): Observable<TournamentTeamUser[]> {
    return this.httpClient.post(
      `/api/v1/tournament_team/${tournamentTeamId}/users/bulk_create/`,
      TournamentTeamUser.toBack(users)
    ).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  deleteTeamUser(tournamentTeamUserId: number): Observable<any> {
    return this.httpClient.delete(`/api/v1/tournament_team_user/${tournamentTeamUserId}/`);
  }

  getTournamentInviteUsers(tournamentInviteId: number): Observable<TournamentTeamUserInvite[]> {
    return this.httpClient.get(`/api/v1/tournament_invite/${tournamentInviteId}/user_invites/`).pipe(
      map(data => TournamentTeamUserInvite.toFront(data))
    );
  }

  updateTournamentInviteUsers(tournamentInviteId: number, userInvites: TournamentTeamUserInvite[]): Observable<TournamentTeamUserInvite[]> {
    return this.httpClient.put(
      `/api/v1/tournament_invite/${tournamentInviteId}/user_invites/bulk/`,
      TournamentTeamUserInvite.toBack(userInvites)
    ).pipe(
      map(data => TournamentTeamUserInvite.toFront(data))
    );
  }

  leaveTournament(tournamentTeamId: number): Observable<any> {
    return this.httpClient.delete(`/api/v1/tournament_team/${tournamentTeamId}/`);
  }

  getTournamentGamesMedia(tournamentId: number, page: number, size: number, filters?: TournamentGamesFilters): Observable<PaginatedResponse<Game[]>> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (filters) {
      if (filters.tournamentTour) {
        params = params.set('tournament_tour', filters.tournamentTour.toString());
      }
      if (filters.teamId) {
        params = params.set('team_id', filters.teamId.toString());
      }
      if (filters.tournamentStageId) {
        params = params.set('tournament_stage_id', filters.tournamentStageId.toString());
      }
      if (filters.playoffId) {
        params = params.set('playoff_id', filters.playoffId.toString());
      }
      if (filters.playoffStage) {
        params = params.set('playoff_stage', filters.playoffStage.toString());
      }
      if (filters.status) {
        params = params.set('status', GameStatuses[filters.status]);
      }
    }
    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/game_media/`, {params, observe: 'response'}).pipe(
      map(response => ({
        total: +response.headers.get('X-Page-Count'),
        data: Game.toFront(response.body)
      }))
    );
  }

  getTeamsForJoin(tournamentId: number): Observable<TournamentJoinTeam[]> {
    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/teams_for_join/`).pipe(
      map(response => TournamentJoinTeam.toFront(response))
    );
  }

  getTournamentTableFile(tournamentId: number, type: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('file_type', type);
    return this.httpClient
      .get(`/api/v1/tournament/${tournamentId}/table_file/`, {params, responseType: 'blob'});
  }
}
