import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MediaItem } from '@core/models/media-item';
import { map } from 'rxjs/operators';
import { Game } from '@core/models/game';
import { VkPhoto } from '@core/models/vk-photo';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { MediaHighlightsSettings } from '@core/models/media-highlights-settings';

export interface MediaFilters {
  tournamentSeasonId?: number;
  tournamentId?: number;
  tournamentTour?: number;
  playoffId?: number;
  playoffRound?: number;
}

@Injectable()
export class OrgMediaService {
  constructor(private httpClient: HttpClient) {}

  getMedia(mediaId: number): Observable<MediaItem> {
    return this.httpClient.get(`/org/api/v1/media/${mediaId}/`).pipe(
      map(result => MediaItem.toFront(result))
    );
  }

  getGameMedia(gameId: number): Observable<Game> {
    return this.httpClient.get(`/org/api/v1/game_media/${gameId}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  uploadVideo(gameId: number, media: MediaItem): Observable<Game> {
    return this.httpClient.post(`/org/api/v1/game_media/${gameId}/upload_video/`, MediaItem.toBack(media)).pipe(
      map(result => Game.toFront(result))
    );
  }

  uploadPhoto(gameId: number, files: any): Observable<Game> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    return this.httpClient.post(`/org/api/v1/game_media/${gameId}/upload/`, formData).pipe(
      map(response => Game.toFront(response))
    );
  }

  deletePhoto(media: MediaItem): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/media/${media.id}`);
  }

  getVkPhotos(albumUrl: string): Observable<VkPhoto[]> {
    const params = new HttpParams().set('album_url', albumUrl);
    return this.httpClient.get(`/org/api/v1/vk_photos/`, {params}).pipe(
      map(result => VkPhoto.toFront(result))
    );
  }

  importVkPhotos(gameId: number, vkPhotos: VkPhoto[]): Observable<Game> {
    const data = vkPhotos.map(item => ({
      id: item.id,
      list_photo_path: item.listPhoto.url,
      detail_photo_path: item.detailPhoto.url,
      original_photo_path: item.originalPhoto.url
    }));
    return this.httpClient.post(`/org/api/v1/game_media/${gameId}/import/`, data).pipe(
      map(result => Game.toFront(result))
    );
  }

  updateMedia(mediaId: number, data: any): Observable<MediaItem> {
    return this.httpClient.patch(`/org/api/v1/media/${mediaId}/`, MediaItem.toBack(data)).pipe(
      map(result => MediaItem.toFront(result))
    );
  }

  getHighlights(leagueId: number, page = 1, size = 20, filters: MediaFilters = {}): Observable<PaginatedResponse<MediaItem[]>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());
    let url = `/org/api/v1/league/${leagueId}/media/highlights/`;
    if (filters.tournamentId) {
      url = `/org/api/v1/tournament/${filters.tournamentId}/media/highlights/`;
    } else if (filters.tournamentSeasonId) {
      url = `/org/api/v1/tournament_season/${filters.tournamentSeasonId}/media/highlights/`;
    }
    if (filters.tournamentTour) {
      params = params.set('tournament_tour', filters.tournamentTour.toString());
    }
    if (filters.playoffId) {
      params = params.set('playoff_id', filters.playoffId.toString());
    }
    if (filters.playoffRound) {
      params = params.set('playoff_round', filters.playoffRound.toString());
    }
    return this.httpClient.get(url, {params, observe: 'response'})
      .pipe(
        map(response => ({
          total: +response.headers.get('X-Page-Count'),
          data: MediaItem.toFront(response.body)
        }))
      );
  }

  async generateHighlightsByGame(gameId: number, settings: MediaHighlightsSettings): Promise<Object> {
    return this.httpClient.post(
      `/org/api/v1/tournament_game/${gameId}/media/generate_highlights/`,
      MediaHighlightsSettings.toBack(settings),
    ).toPromise();
  }
}
