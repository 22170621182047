import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input, OnInit, Output
} from '@angular/core';
import { Game, GameStatuses } from '@core/models/game';
import { Playoff } from '@core/models/playoff';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import tournamentPlayoffLocalization from '@shared/modules/shared/components/tournament-playoff/tournament-playoff.localization';

@Component({
  selector: 'mtg-tournament-playoff-winner-bracket',
  templateUrl: './tournament-playoff-winner-bracket.component.html',
  styleUrls: [
    './tournament-playoff-winner-bracket.component.scss',
    '../shared/playoff-bracket.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentPlayoffWinnerBracketComponent {
  @Input()
  games: Game[];
  @Input()
  playoff: Playoff;
  @Input()
  gameRoute = '';
  @Input()
  canManage: boolean;
  @Input()
  openGameInNewWindow: boolean;
  @Output()
  gameForm = new EventEmitter<{playoffNumber: string, gameId: number}>();
  @Input()
  currentRound: number;
  localization = tournamentPlayoffLocalization;
  currentLocale = this.localizationService.getLocale();

  get toursCount(): number {
    return (Math.log(this.playoff.settings.teamsCount) / Math.log(2)) + 1;
  }

  getRoundNumber(i: number, g: number = 0): any {
    const tour = i + 1;
    const winnerPlace = this.playoff.winnerPlace;
    let finalTitle = this.localization[this.currentLocale].final;
    let thirdPlaceTitle = this.localization[this.currentLocale].third + ' ' + this.localization[this.currentLocale].place;
    if (winnerPlace > 1) {
      finalTitle = winnerPlace + ' ' + this.localization[this.currentLocale].place;
      thirdPlaceTitle = (winnerPlace + 2) + ' ' + this.localization[this.currentLocale].place;
    }
    if (tour === this.toursCount) {
      if (g === 0) {
        return finalTitle;
      } else {
        return thirdPlaceTitle;
      }
    } else if (tour === this.toursCount - 1 && this.playoff.settings.loserFinalRounds === 0) {
      return this.localization[this.currentLocale].semiFinals;
    } else {
      return this.localization[this.currentLocale].round + ' ' + tour;
    }
  }

  getMatchesCount(i: number): number {
    const tour = i + 1;
    if (tour === this.toursCount - 1) {
      if (this.playoff.settings.loserFinalRounds > 0) {
        return 1;
      }
      return 2;
    } else {
      return this.playoff.settings.teamsCount / Math.pow(2, tour);
    }
  }

  generateArray(length: number): any[] {
    return new Array(Math.ceil(length));
  }

  getGames(tourNumber: number, gameNumber: number): Game[] {
    return this.games.filter(game => game.playoffNumber === `${tourNumber + 1}_${gameNumber + 1}`).sort((a, b) => a.id - b.id);
  }

  getGameNumber(tour: number, game: number): number {
    if (tour + 1 === this.toursCount && this.playoff.settings.loserFinalRounds === 0) {
      return 2 + game + this.playoff.settings.teamsCount - this.playoff.settings.teamsCount / Math.pow(2, tour);
    }
    return 1 + game + this.playoff.settings.teamsCount - this.playoff.settings.teamsCount / Math.pow(2, tour);
  }

  getNextGameTeam(tour: number, game: number, team: number = 0): any {
    if (tour === 0) {
      return;
    }
    const matchesPrevRound = this.getMatchesCount(tour - 1);
    const gameNumber = this.getGameNumber(tour, game);
    let number = (gameNumber - matchesPrevRound) + game + team;
    if (this.playoff.settings.loserFinalRounds === 0) {
      if (tour + 1 === this.toursCount) {
        if (game === 1) {
          number = (gameNumber - 1 - matchesPrevRound) + team;
          return this.localization[this.currentLocale].loser + ' ' + number;
        }
      }
      if (tour + 2 === this.toursCount) {
        if (team === 1) {
          number = gameNumber - 4;
          return this.localization[this.currentLocale].winner + ' L' + number;
        } else {
          number = gameNumber - matchesPrevRound;
          return this.localization[this.currentLocale].winner + ' ' + number;
        }
      }
    } else if (tour + 1 === this.toursCount) {
      if (game === 1) {
        if (team === 0) {
          return this.localization[this.currentLocale].loser + ' ' + (gameNumber - 1 - matchesPrevRound);
        } else {
          return this.localization[this.currentLocale].loser + ' L' + (gameNumber - 3);
        }
      } else {
        if (team === 0) {
          return this.localization[this.currentLocale].winner + ' ' + number;
        } else {
          return this.localization[this.currentLocale].winner + ' L' + (gameNumber - 2);
        }
      }
    }
    return this.localization[this.currentLocale].winner + ' ' + number;
  }

  getTitle(tour: number): string {
    const matchesCount = this.getMatchesCount(tour);
    if (matchesCount === 4) {
      return this.localization[this.currentLocale].quarterfinals;
    } else if (matchesCount === 2) {
      return this.localization[this.currentLocale].semiFinals;
    } else if (matchesCount === 1) {
      return this.localization[this.currentLocale].final;
    } else {
      return `1/${matchesCount} ${this.localization[this.currentLocale].finalEndText}`;
    }
  }

  isPrevSemi(i: number): boolean {
    return this.toursCount === i + 3 && this.playoff.settings.loserFinalRounds === 0;
  }

  isSemi(i: number): boolean {
    return this.toursCount === i + 2 && this.playoff.settings.loserFinalRounds === 0;
  }

  isWinnerFinal(i: number): boolean {
    return this.toursCount === i + 2 && this.playoff.settings.loserFinalRounds > 0;
  }

  constructor(
    private localizationService: LocalizationService,
  ) {}
}
