import { BaseModel, ModelInstance, ToBackHook, ToFrontHook } from '@core/models/util';

@ModelInstance({
  mappingFields: {
    start_time_adjustment: 'startTimeAdjustment',
  }
})
export class MediaHighlightsSettings extends BaseModel {
  startTimeAdjustment: number;

  @ToFrontHook
  static toFront(data: any): any {
  }

  @ToBackHook
  static toBack(data: any): any {
  }
}
