import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, listField } from './util';
import { generateArray } from '@shared/util/util';

@ModelInstance({
  mappingFields: {
    stage_id: 'stageId',
    tours_count: 'toursCount',
    playoff_stages_count: 'playoffStagesCount',
    playoff_teams_count: 'playoffTeamsCount',
  }
})
export class GameTimelineStageItem {
  stageId: number;
  toursCount: number;
  playoffStagesCount: number;
  playoffTeamsCount: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    tours_count: 'toursCount',
    playoff_stages_count: 'playoffStagesCount',
    playoff_teams_count: 'playoffTeamsCount',
    current_tour: 'currentTour',
    current_playoff_stage: 'currentPlayoffStage',
    current_playoff_round: 'currentPlayoffRound',
    stages: 'stages',
    tournament_stage_id: 'tournamentStageId',
    tournament_round_id: 'tournamentRoundId',
  },
  relation: {
    stages: listField(GameTimelineStageItem)
  }
})
export class GameTimelineStages extends BaseModel {
  toursCount: number;
  playoffStagesCount: number;
  playoffTeamsCount: number;
  currentTour: number;
  currentPlayoffStage: number;
  currentPlayoffRound: number;
  stages: GameTimelineStageItem[];
  tournamentStageId: number;
  tournamentRoundId: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

export interface GameStage {
  id: string;
  title: string;
  filters: {playoffRound: number, tournamentTour: number};
}

export function getGameStages(gameStages: GameTimelineStages): GameStage[] {
  return generateArray(gameStages.toursCount)
    .map(tournamentTour => ({
      id: `tour_${tournamentTour}`,
      title: `${tournamentTour} тур`,
      filters: {tournamentTour, playoffRound: null}
    }))
    .concat(
      generateArray(getPlayoffStagesCount(gameStages.playoffTeamsCount))
        .map(playoffStage => {
          const playoffRound = gameStages.playoffTeamsCount / 2 ** playoffStage;
          let stageTitle = '';
          if (playoffRound === 1) {
            stageTitle = 'Финал';
          } else {
            stageTitle = `1/${playoffRound}`;
          }
          return {
            id: `playoff_${playoffStage}`,
            title: stageTitle,
            filters: {playoffRound, tournamentTour: null}
          };
        })
    );
}

export function getPlayoffStagesCount(playoffTeamsCount: number): number {
  if (playoffTeamsCount) {
    return Math.log(playoffTeamsCount) / Math.log(2);
  }
  return 0;
}

