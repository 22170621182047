import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Tournament } from '@core/models/tournament';
import { TournamentTeam } from '@core/models/tournament-team';
import { TournamentGroup } from '@core/models/tournament-group';
import { TournamentInvite } from '@core/models/tournament-invite';
import { Team } from '@core/models/team';
import { AuthService } from '@core/services/auth.service';
import { TournamentNews } from '@core/models/tournament-news';
import { Game, GameCloseResult, GameStatuses } from '@core/models/game';
import { Playoff } from '@core/models/playoff';
import { TournamentStage } from '@core/models/tournament-stage';
import { TournamentStageTeam } from '@core/models/tournament-stage-team';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { TournamentTeamUserInvite } from '@core/models/tournament-team-user-invite';
import { PlayoffAdmissionEnum, TournamentTeamUser } from '@core/models/tournament-team-user';
import { TournamentDisqualification } from '@core/models/tournament-disqualification';
import { Notification } from '@core/models/notification';
import { GameMediaFilters } from '@core/services/tournament.service';
import { LeagueUserPermissions } from '@core/models/league-user';
import { TeamUser, TeamUserRole } from '@core/models/team-user';
import { User } from '@core/models/user';
import { LeaguePlayerForm } from '@core/models/league-player-form';

export interface TournamentGamesFilters {
  tournamentIds?: number[];
  tournamentSeasonId?: number;
  tournamentStageId?: number;
  tournamentRoundId?: number;
  tournamentTour?: number;
  divisionId?: number;
  groupId?: number;
  teamId?: number;
  playoffStage?: number;
  playoffRound?: number;
  playoffId?: number;
  isPlayoff?: boolean;
  status?: GameStatuses;
  statuses?: GameStatuses[];
  tournamentCourtId?: number;
  leaguePlayerId?: number;
  userId?: number;
  query?: string;
}

export type GameBatchLoadAction = 'create' | 'update' | 'delete';

@Injectable()
export class TournamentAdminService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  getTournament(tournamentId: number): Observable<Tournament> {
    return this.httpClient.get(`/org/api/v1/tournament/${tournamentId}/`).pipe(
      map(data => Tournament.toFront(data))
    );
  }

  updateTournament(tournamentId: number, tournament: Tournament): Observable<Tournament> {
    return this.httpClient.patch(`/org/api/v1/tournament/${tournamentId}/`, Tournament.toBack(tournament)).pipe(
      map(data => Tournament.toFront(data))
    );
  }

  closeTournament(tournamentId: number): Observable<Tournament> {
    return this.httpClient.post(`/org/api/v1/tournament/${tournamentId}/close/`, {}).pipe(
      map(result => Tournament.toFront(result))
    );
  }

  myPermission(tournamentId: number): Observable<LeagueUserPermissions[]> {
    return this.httpClient.get(`/org/api/v1/tournament/${tournamentId}/permission/`).pipe(
      map(data => (data['permissions'] || []).map(item => LeagueUserPermissions[item]))
    );
  }

  getTournamentTeams(tournamentId: number): Observable<TournamentTeam[]> {
    return this.httpClient.get(`/org/api/v1/tournament/${tournamentId}/teams/`).pipe(
      map(data => TournamentTeam.toFront(data))
    );
  }

  removeTeam(teamId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/tournament_team/${teamId}/`);
  }

  getTournamentInviteUsers(inviteId: number): Observable<TournamentTeamUserInvite[]> {
    return this.httpClient.get(`/org/api/v1/tournament_invite/${inviteId}/user_invites/`).pipe(
      map(data => TournamentTeamUserInvite.toFront(data))
    );
  }

  getTeamUsers(tournamentTeamId: number): Observable<TournamentTeamUser[]> {
    return this.httpClient.get(`/org/api/v1/tournament_team/${tournamentTeamId}/users/`).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  getUsers(tournamentTeamId: number): Observable<TeamUser[]> {
    return this.httpClient.get(`/org/api/v1/tournament_team/${tournamentTeamId}/team_users/`).pipe(
      map(data => TeamUser.toFront(data))
    );
  }

  getTeamUserInvites(tournamentTeamId: number): Observable<TournamentTeamUserInvite[]> {
    return this.httpClient.get(`/org/api/v1/tournament_team/${tournamentTeamId}/user_invites/`).pipe(
      map(data => TournamentTeamUserInvite.toFront(data))
    );
  }

  acceptTeamUserInvite(userInviteId: number): Observable<TournamentTeamUserInvite> {
    return this.httpClient.post(`/org/api/v1/tournament_team_user_invite/${userInviteId}/accept/`, {}).pipe(
      map(data => TournamentTeamUserInvite.toFront(data))
    );
  }

  declineTeamUserInvite(userInviteId: number): Observable<TournamentTeamUserInvite> {
    return this.httpClient.post(`/org/api/v1/tournament_team_user_invite/${userInviteId}/decline/`, {}).pipe(
      map(data => TournamentTeamUserInvite.toFront(data))
    );
  }

  acceptTeamUserChanges(tournamentTeamUserId: number): Observable<TournamentTeamUser> {
    return this.httpClient.post(`/org/api/v1/tournament_team_user/${tournamentTeamUserId}/confirm_changes/`, {}).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  declineTeamUserChanges(tournamentTeamUserId: number): Observable<TournamentTeamUser> {
    return this.httpClient.post(`/org/api/v1/tournament_team_user/${tournamentTeamUserId}/decline_changes/`, {}).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  disqualifyUser(tournamentTeamUserId: number, disqualification: TournamentDisqualification): Observable<TournamentTeamUser> {
    return this.httpClient.post(
      `/org/api/v1/tournament_team_user/${tournamentTeamUserId}/disqualify/`,
      TournamentDisqualification.toBack(disqualification)
    ).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  cancelDisqualification(tournamentTeamUserId: number): Observable<TournamentTeamUser> {
    return this.httpClient.post(
      `/org/api/v1/tournament_team_user/${tournamentTeamUserId}/cancel_disqualification/`,
      null
    ).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  conditionalDisqualifyUser(tournamentTeamUserId: number, disqualification: TournamentDisqualification): Observable<TournamentTeamUser> {
    return this.httpClient.post(
      `/org/api/v1/tournament_team_user/${tournamentTeamUserId}/conditional_disqualify/`,
      TournamentDisqualification.toBack(disqualification)
    ).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  cancelConditionalDisqualification(tournamentTeamUserId: number): Observable<TournamentTeamUser> {
    return this.httpClient.post(
      `/org/api/v1/tournament_team_user/${tournamentTeamUserId}/cancel_conditional_disqualification/`,
      null
    ).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  exportUsers(tournamentIds: number[]): Observable<Blob> {
    let params = new HttpParams();
    if (tournamentIds) {
      params = params.set('tournament_ids', tournamentIds.join(','));
    }
    return this.httpClient.get(`/org/api/v1/tournament_team_user/export_to_file/`, {params, responseType: 'blob'});
  }

  exportLeagueSanctionList(tournamentIds: number[]): Observable<Blob> {
    let params = new HttpParams();
    if (tournamentIds) {
      params = params.set('tournament_ids', tournamentIds.join(','));
    }
    return this.httpClient.get(`/org/api/v1/tournament_team_user/export_sanction_list/`, {params, responseType: 'blob'});
  }

  addUser(tournamentTeamId: number, teamUser: TeamUser): Observable<TournamentTeamUser> {
    return this.httpClient.post(`/org/api/v1/tournament_team/${tournamentTeamId}/users/`, {team_user: {id: teamUser.id}})
      .pipe(map(data => TournamentTeamUser.toFront(data)));
  }

  inviteUser(tournamentTeamId: number, user: User): Observable<TournamentTeamUser> {
    return this.httpClient.post(`/org/api/v1/tournament_team/${tournamentTeamId}/invite_user/`, {user})
      .pipe(map(data => TournamentTeamUser.toFront(data)));
  }

  registerLazyUser(tournamentTeamId: number, userData: User): Observable<TournamentTeamUser> {
    return this.httpClient.post(`/org/api/v1/tournament_team/${tournamentTeamId}/lazy_user/`, LeaguePlayerForm.toBack(userData))
      .pipe(map(data => TournamentTeamUser.toFront(data)));
  }

  updateRating(tournamentTeamUserId: number, rating: any): Observable<TournamentTeamUser> {
    return this.httpClient.patch(
      `/org/api/v1/tournament_team_user/${tournamentTeamUserId}/rating/`,
      {rating}
    ).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  updatePlayoffAdmission(tournamentTeamUserId: number, playoffAdmission: PlayoffAdmissionEnum): Observable<TournamentTeamUser> {
    return this.httpClient.patch(
      `/org/api/v1/tournament_team_user/${tournamentTeamUserId}/playoff_admission/`,
      {playoff_admission: PlayoffAdmissionEnum[playoffAdmission]}
    ).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  updateUserNumber(tournamentTeamUserId: number, number: number): Observable<TournamentTeamUser> {
    return this.httpClient.patch(
      `/org/api/v1/tournament_team_user/${tournamentTeamUserId}/number/`,
      {number}
    ).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  updateUserRole(tournamentTeamUserId: number, role: TeamUserRole): Observable<TournamentTeamUser> {
    return this.httpClient.patch(
      `/org/api/v1/tournament_team_user/${tournamentTeamUserId}/role/`,
      {role: TeamUserRole[role]}
    ).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  changeOwner(tournamentTeamUserId: number): Observable<TournamentTeamUser> {
    return this.httpClient.post(`/org/api/v1/tournament_team_user/${tournamentTeamUserId}/change_owner/`, {}).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }

  deleteUser(tournamentTeamUserId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/tournament_team_user/${tournamentTeamUserId}/`);
  }

  markViewedTournamentTeamNotifications(tournamentTeamId: number): Observable<Notification[]> {
    return this.httpClient.post(`/org/api/v1/tournament_team/${tournamentTeamId}/notifications/viewed/`, {}).pipe(
      map(data => Notification.toFront(data))
    );
  }

  markViewedTournamentInviteNotifications(tournamentInviteId: number): Observable<Notification[]> {
    return this.httpClient.post(`/org/api/v1/tournament_invite/${tournamentInviteId}/notifications/viewed/`, {}).pipe(
      map(data => Notification.toFront(data))
    );
  }

  getTournamentGroups(tournamentId: number): Observable<TournamentGroup[]> {
    return this.httpClient.get(`/org/api/v1/tournament/${tournamentId}/groups/`).pipe(
      map(data => TournamentGroup.toFront(data))
    );
  }

  getTournamentNews(tournamentId: number): Observable<TournamentNews[]> {
    return this.httpClient.get(`/org/api/v1/tournament/${tournamentId}/news/`).pipe(
      map(data => TournamentNews.toFront(data))
    );
  }

  getInvites(tournamentId: number): Observable<TournamentInvite[]> {
    return this.httpClient
      .get(`/org/api/v1/tournament/${tournamentId}/invites/`)
      .pipe(
        map(data => TournamentInvite.toFront(data))
      );
  }

  acceptInvite(invite: TournamentInvite, userInvites: TournamentTeamUserInvite[]): Observable<TournamentInvite> {
    return this.httpClient
      .post(`/org/api/v1/tournament_invite/${invite.id}/accept/`, TournamentTeamUserInvite.toBack(userInvites))
      .pipe(
        map(data => TournamentInvite.toFront(data))
      );
  }

  declineInvite(invite: TournamentInvite): Observable<TournamentInvite> {
    return this.httpClient
      .post(`/org/api/v1/tournament_invite/${invite.id}/decline/`, {})
      .pipe(
        map(data => TournamentInvite.toFront(data))
      );
  }

  sendInvite(tournamentId: number, team: Team): Observable<TournamentInvite> {
    return this.httpClient
      .post(`/org/api/v1/tournament/${tournamentId}/invites/`, {team})
      .pipe(
        map(data => TournamentInvite.toFront(data))
      );
  }

  addTeamCreatedByLeague(tournamentId: number, team: Team): Observable<TournamentTeam> {
    return this.httpClient
      .post(`/org/api/v1/tournament/${tournamentId}/teams/`, {team: {id: team.id}})
      .pipe(
        map(data => TournamentTeam.toFront(data))
      );
  }

  getTournamentGames(tournamentId?: number, filters?: TournamentGamesFilters, page = 1, size = 1000): Observable<PaginatedResponse<Game[]>> {
    let params = new HttpParams()
      .set('size', size.toString())
      .set('page', page.toString());
    if (filters) {
      params = applyGamesFilters(filters, params);
    }
    return this.httpClient
      .get(`/org/api/v1/tournament/${tournamentId}/games/`, {params, observe: 'response'}).pipe(
        map(response => ({
          total: +response.headers.get('X-Page-Count'),
          data: Game.toFront(response.body)
        }))
      );
  }

  getGame(gameId: number): Observable<Game> {
    return this.httpClient
      .get(`/org/api/v1/tournament_game/${gameId}/`).pipe(
        map(data => Game.toFront(data))
      );
  }

  createGame(tournamentId: number, game: Game): Observable<Game> {
    return this.httpClient
      .post(`/org/api/v1/tournament/${tournamentId}/games/`, Game.toBack(game)).pipe(
        map(data => Game.toFront(data))
      );
  }

  updateGame(gameId: number, data: any): Observable<Game> {
    return this.httpClient
      .patch(`/org/api/v1/tournament_game/${gameId}/`, Game.toBack(data)).pipe(
        map(result => Game.toFront(result))
      );
  }

  deleteGame(gameId: number): Observable<any> {
    return this.httpClient
      .delete(`/org/api/v1/tournament_game/${gameId}/`);
  }

  batchLoadGames(data: {action: GameBatchLoadAction, game: Game}[]): Observable<Game[]> {
    return this.httpClient.post('/org/api/v1/tournament_game/batch/', data.map(item => ({
      action: item.action,
      game: Game.toBack(item.game)
    }))).pipe(
      map(result => Game.toFront(result))
    );
  }

  openGame(gameId: number): Observable<Game> {
    return this.httpClient.post(`/org/api/v1/tournament_game/${gameId}/open/`, {}).pipe(
      map(result => Game.toFront(result))
    );
  }

  closeGameWithResult(gameId: number, data: GameCloseResult): Observable<Game> {
    return this.httpClient.post(`/org/api/v1/tournament_game/${gameId}/close_with_result/`, data ? GameCloseResult.toBack(data) : {}).pipe(
      map(result => Game.toFront(result))
    );
  }

  changeGameTournament(gameId: number, tournamentId: number): Observable<Game> {
    return this.httpClient.post(`/org/api/v1/tournament_game/${gameId}/change_tournament/`, {id: tournamentId}).pipe(
      map(result => Game.toFront(result))
    );
  }

  changeGameTournamentRound(gameId: number, tournamentRoundId: number): Observable<Game> {
    return this.httpClient.post(`/org/api/v1/tournament_game/${gameId}/change_tournament_round/`, {id: tournamentRoundId}).pipe(
      map(result => Game.toFront(result))
    );
  }

  generateTourGames(tournamentId: number): Observable<Game[]> {
    return this.httpClient
      .post(`/org/api/v1/tournament/${tournamentId}/generate_tours/`, {}).pipe(
        map(data => Game.toFront(data))
      );
  }

  resendInvite(invite: TournamentInvite, tournamentId: number): Observable<TournamentInvite> {
    return this.httpClient
      .post(`/org/api/v1/tournament/${tournamentId}/invites/${invite.id}/resend/`, {})
      .pipe(
        map(data => TournamentInvite.toFront(data))
      );
  }

  getPlayoff(tournamentId: number): Observable<Playoff[]> {
    return this.httpClient
      .get(`/org/api/v1/tournament/${tournamentId}/playoff/`).pipe(
        map(data => Playoff.toFront(data))
      );
  }

  getPlayoffById(playoffId: number): Observable<Playoff> {
    return this.httpClient
      .get(`/org/api/v1/tournament_playoff/${playoffId}`).pipe(
        map(data => Playoff.toFront(data))
      );
  }

  createPlayoff(tournamentId: number, playoff: Playoff): Observable<Playoff> {
    return this.httpClient
      .post(`/org/api/v1/tournament/${tournamentId}/playoff/`, Playoff.toBack(playoff)).pipe(
        map(data => Playoff.toFront(data))
      );
  }

  updatePlayoff(playoffId: number, values: any): Observable<Playoff> {
    return this.httpClient
      .patch(`/org/api/v1/tournament_playoff/${playoffId}/`, Playoff.toBack(values)).pipe(
        map(data => Playoff.toFront(data))
      );
  }

  deletePlayoff(playoffId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/tournament_playoff/${playoffId}/`);
  }

  getPlayoffGames(playoffId: number): Observable<Game[]> {
    return this.httpClient.get(`/org/api/v1/tournament_playoff/${playoffId}/games/`).pipe(
      map(data => Game.toFront(data))
    );
  }

  generatePlayoffGames(playoffId: number, teamIds?: number[], roundIds?: number[]): Observable<Game[]> {
    return this.httpClient.post(
      `/org/api/v1/tournament_playoff/${playoffId}/generate_games/`,
      {team_ids: teamIds, tournament_round_ids: roundIds}
    ).pipe(
      map(data => Game.toFront(data))
    );
  }

  getTournamentStages(tournamentId: number): Observable<TournamentStage[]> {
    return this.httpClient.get(`/org/api/v1/tournament/${tournamentId}/stages/`).pipe(
      map(data => TournamentStage.toFront(data))
    );
  }

  getTournamentStage(tournamentStageId: number): Observable<TournamentStage> {
    return this.httpClient.get(`/org/api/v1/tournament_stage/${tournamentStageId}/`).pipe(
      map(data => TournamentStage.toFront(data))
    );
  }

  createTournamentStage(tournamentId: number, tournamentStage: TournamentStage): Observable<TournamentStage> {
    return this.httpClient.post(`/org/api/v1/tournament/${tournamentId}/stages/`, TournamentStage.toBack(tournamentStage)).pipe(
      map(data => TournamentStage.toFront(data))
    );
  }

  updateTournamentStage(tournamentStageId: number, data: any): Observable<TournamentStage> {
    return this.httpClient.patch(`/org/api/v1/tournament_stage/${tournamentStageId}/`, TournamentStage.toBack(data)).pipe(
      map(res => TournamentStage.toFront(res))
    );
  }

  deleteTournamentStage(tournamentStageId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/tournament_stage/${tournamentStageId}/`);
  }

  getTournamentStageGroups(tournamentStageId: number): Observable<TournamentGroup[]> {
    return this.httpClient.get(`/org/api/v1/tournament_stage/${tournamentStageId}/groups/`).pipe(
      map(data => TournamentGroup.toFront(data))
    );
  }

  createTournamentStageGroup(tournamentStageId: number, group: TournamentGroup, teamIds: number[]): Observable<TournamentGroup> {
    const payload = TournamentGroup.toBack(group);
    if (teamIds) {
      payload['team_ids'] = teamIds;
    }
    return this.httpClient.post(`/org/api/v1/tournament_stage/${tournamentStageId}/groups/`, payload).pipe(
      map(data => TournamentGroup.toFront(data))
    );
  }

  getTournamentStageTeams(tournamentStageId: number): Observable<TournamentStageTeam[]> {
    return this.httpClient.get(`/org/api/v1/tournament_stage/${tournamentStageId}/teams/`).pipe(
      map(data => TournamentStageTeam.toFront(data))
    );
  }

  getTournamentStagePlayoff(tournamentStageId: number): Observable<Playoff[]> {
    return this.httpClient.get(`/org/api/v1/tournament_stage/${tournamentStageId}/playoff/`).pipe(
      map(data => Playoff.toFront(data))
    );
  }

  createTournamentStagePlayoff(tournamentStageId: number, playoff: Playoff): Observable<Playoff> {
    return this.httpClient.post(`/org/api/v1/tournament_stage/${tournamentStageId}/playoff/`, Playoff.toBack(playoff)).pipe(
      map(data => Playoff.toFront(data))
    );
  }

  getTournamentStageGames(tournamentStageId: number, filters?: TournamentGamesFilters, page?: number, size?: number): Observable<Game[]> {
    let params = new HttpParams();
    if (filters) {
      params = applyGamesFilters(filters, params);
    }
    if (page) {
      params = params.set('page', page.toString());
    }
    if (size) {
      params = params.set('size', size.toString());
    }
    return this.httpClient.get(`/org/api/v1/tournament_stage/${tournamentStageId}/games/`, {params}).pipe(
      map(data => Game.toFront(data))
    );
  }

  generateTournamentStageTours(tournamentStageId: number): Observable<Game[]> {
    return this.httpClient.post(`/org/api/v1/tournament_stage/${tournamentStageId}/generate_tours/`, {}).pipe(
      map(data => Game.toFront(data))
    );
  }

  createTournamentStageGame(tournamentStageId: number, game: Game): Observable<Game> {
    return this.httpClient.post(`/org/api/v1/tournament_stage/${tournamentStageId}/games/`, Game.toBack(game)).pipe(
      map(data => Game.toFront(data))
    );
  }

  getTournamentGamesMedia(tournamentId: number, page: number, size: number, filters?: TournamentGamesFilters): Observable<PaginatedResponse<Game[]>> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (filters) {
      if (filters.tournamentTour) {
        params = params.set('tournament_tour', filters.tournamentTour.toString());
      }
      if (filters.teamId) {
        params = params.set('team_id', filters.teamId.toString());
      }
      if (filters.tournamentStageId) {
        params = params.set('tournament_stage_id', filters.tournamentStageId.toString());
      }
      if (filters.playoffStage) {
        params = params.set('playoff_stage', filters.playoffStage.toString());
      }
      if (filters.playoffId) {
        params = params.set('playoff_id', filters.playoffId.toString());
      }
      if (filters.groupId) {
        params = params.set('tournament_group_id', filters.groupId.toString());
      }
    }
    return this.httpClient.get(`/org/api/v1/tournament/${tournamentId}/game_media/`, {params, observe: 'response'}).pipe(
      map(response => ({
        total: +response.headers.get('X-Page-Count'),
        data: Game.toFront(response.body)
      }))
    );
  }
}

export function applyGamesFilters(filters: TournamentGamesFilters, params: HttpParams): HttpParams {
  if (!filters) {
    return params;
  }
  if (filters.tournamentIds) {
    params = params.set('tournament_ids', filters.tournamentIds.join(','));
  }
  if (filters.tournamentStageId) {
    params = params.set('tournament_stage_id', filters.tournamentStageId.toString());
  }
  if (filters.tournamentRoundId) {
    params = params.set('tournament_round_id', filters.tournamentRoundId.toString());
  }
  if (filters.tournamentTour) {
    params = params.set('tournament_tour', filters.tournamentTour.toString());
  }
  if (filters.divisionId) {
    params = params.set('division_id', filters.divisionId.toString());
  }
  if (filters.groupId) {
    params = params.set('tournament_group_id', filters.groupId.toString());
  }
  if (filters.playoffId) {
    params = params.set('playoff_id', filters.playoffId.toString());
  }
  if (filters.playoffStage) {
    params = params.set('playoff_stage', filters.playoffStage.toString());
  }
  if (filters.playoffRound) {
    params = params.set('playoff_round', filters.playoffRound.toString());
  }
  if (filters.teamId) {
    params = params.set('team_id', filters.teamId.toString());
  }
  if (filters.status) {
    params = params.set('status', GameStatuses[filters.status]);
  }
  if (filters.statuses) {
    const statuses = filters.statuses.map(i => GameStatuses[i]);
    params = params.set('statuses', statuses.join(','));
  }
  if (filters.tournamentCourtId) {
    params = params.set('tournament_court_id', filters.tournamentCourtId.toString());
  }
  if (filters.leaguePlayerId) {
    params = params.set('league_player_id', filters.leaguePlayerId.toString());
  }
  if (filters.userId) {
    params = params.set('user_id', filters.userId.toString());
  }
  if (filters.query) {
    params = params.set('query', filters.query);
  }
  if (filters.isPlayoff !== undefined && filters.isPlayoff !== null) {
    params = params.set('is_playoff', filters.isPlayoff ? '1' : '0');
  }
  return params;
}
