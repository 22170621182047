import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { Team } from '@core/models/team';
import { LeagueTeamForm } from '@core/models/league-team-form';
import { TournamentTeam } from '@core/models';
import { TeamUser } from '@core/models/team-user';

export interface LeagueTeamsListFilters {
  name?: string;
  tournament?: number;
  season?: number;
  isLeagueTeam?: number;
}

@Injectable()
export class OrgLeagueTeamService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  getTeamList(leagueId: number, page: number, size: number, filters?: LeagueTeamsListFilters): Observable<PaginatedResponse<Team[]>> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (filters.name) {
      params = params.set('search', filters.name);
    }
    if (filters.tournament) {
      params = params.set('tournament', filters.tournament.toString());
    }
    if (filters.season) {
      params = params.set('season', filters.season.toString());
    }
    if (filters.isLeagueTeam) {
      params = params.set('created_by_league', filters.isLeagueTeam.toString());
    }
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/teams/`, {observe: 'response', params})
      .pipe(map(response => ({
        total: +response.headers.get('X-Page-Count'),
        data: Team.toFront(response.body)
      })));
  }

  getLeagueTournamentTeamsList(leagueId: number, page: number, size: number, onlyChanges?: boolean): Observable<PaginatedResponse<TournamentTeam[]>> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (onlyChanges) {
      params = params.set('has_changes', '1');
    }
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/tournament_teams/`, {observe: 'response', params})
      .pipe(map(response => ({
        total: +response.headers.get('X-Page-Count'),
        data: TournamentTeam.toFront(response.body)
      })));
  }

  createTeam(leagueId: number, data: any): Observable<Team> {
    return this.httpClient.post(`/org/api/v1/league/${leagueId}/teams/`, LeagueTeamForm.toBack(data))
      .pipe(map(result => Team.toFront(result)));
  }

  updateTeam(teamId: number, data: any, updateTournamentTeams?: boolean, leagueId?: number): Observable<Team> {
    let params = new HttpParams();
    if (updateTournamentTeams && leagueId) {
      params = params.set('update_tournament_teams', updateTournamentTeams.toString()).set('league', leagueId.toString());
    }
    return this.httpClient.patch(`/org/api/v1/league_team/${teamId}/`, Team.toBack(data), {params})
      .pipe(map(result => Team.toFront(result)));
  }

  deleteTeam(teamId: number, leagueId: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('league_id', leagueId.toString());
    return this.httpClient.delete(`/org/api/v1/league_team/${teamId}/`, {params});
  }

  updateTournamentTeam(tournamentTeamId: number, data: any): Observable<TournamentTeam> {
    return this.httpClient.patch(`/org/api/v1/tournament_team/${tournamentTeamId}/`, TournamentTeam.toBack(data))
      .pipe(map(result => TournamentTeam.toFront(result)));
  }

  getTeamById(teamId: number): Observable<Team> {
    return this.httpClient
      .get(`/org/api/v1/league_team/${teamId}/`)
      .pipe(
        map(data => Team.toFront(data))
      );
  }

  getTournamentTeams(teamId: number, leagueId: number): Observable<TournamentTeam[]> {
    const params = new HttpParams().set('league_id', leagueId.toString());
    return this.httpClient
      .get(`/org/api/v1/league_team/${teamId}/tournament_teams/`, {params})
      .pipe(
        map(data => TournamentTeam.toFront(data))
      );
  }

  getUsers(teamId: number): Observable<TeamUser[]> {
    return this.httpClient
      .get(`/org/api/v1/league_team/${teamId}/team_users/` )
      .pipe(
        map(data => TeamUser.toFront(data))
      );
  }

  confirmTeamChanges(teamId: number): Observable<TournamentTeam> {
    return this.httpClient.post(`/org/api/v1/tournament_team/${teamId}/confirm_changes/`, {})
      .pipe(map(result => TournamentTeam.toFront(result)));
  }

  declineTeamChanges(teamId: number): Observable<TournamentTeam> {
    return this.httpClient.post(`/org/api/v1/tournament_team/${teamId}/decline_changes/`, {})
      .pipe(map(result => TournamentTeam.toFront(result)));
  }
}
